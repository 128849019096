 import React, {useEffect, useRef} from 'react'
import {connect} from 'react-redux';
import { getProfileInformation, getBooksToView, Logout, setNotifications } from '../redux/actions'
import firebase from 'firebase'


function StartUp(props) {

    // const socket = io('http://127.0.0.1:8080');

    useEffect(() => {
        if (props.email) { 
            props.getProfileInformation(props.email);
            props.getBooksToView(40, props.lat, props.long, props.email)
             firebase.database().ref(`Ouser/${props.email}/notifications`)
                .once('value', function(snapshot) {
                    if (snapshot.val()) {
                        let notifs = Object.values(snapshot.val());
                        let numberNotifs = notifs.reduce((accum, current) => {
                            if (current.seen === false) {
                                accum += 1
                            }
                            return accum;
                        },0);

                        props.setNotifications(numberNotifs, notifs, true )
                    }

            })
            // socket.on('connect',console.log('hello'))
            // Here we should put a check that checks to see if any of their books have been liked - maybe in the same call as getProfileInformation
    }
    }, [props.email])
    
    return props.children

}

const mapStateToProps = state => {
    return {
        email: state.auth.email,
        books: state.books.books,
        lat: state.auth.lat,
        long: state.auth.long
    }
}

export default connect(mapStateToProps, {getProfileInformation, getBooksToView, Logout, setNotifications})(StartUp)