const colors = {
    lightblue: '#24b9ed',
    lightblue2: '#6385FF',
    lightPurple: '#ACB3F4',
    darkPurple: '#6424ED',
    turquoiseLighter: '#40cac2',
    blueLight: '#029fe0',
    blueLightLighter: '#41b7e7',
    green: '#4abc89',
    purple: '#b6398e',
    coral: '#f26a73',
    coralLighter: '#f7abb0',
    blueDarkLighter: '#404f60',
    orange: '#f9a254',
    yellow: '#ffc809',
    grey: '#b5aca6',
    greyCool3: '#bfc4ca',
    greyCool2: '#e5e7e9',
    greyCool1: '#f2f3f4',
    white: '#fff',
    black: '#000',
    silver: '#d8d8d8',
    blueDark: '#00152b',
    darkSexyBlue: '#0a4794'
  }

  export const fonts = {
    Elite: 'Special Elite, cursive',
    Chelsea: 'Chelsea Market, cursive',
    Roboto: 'Roboto, cursive',
    PatrickHand: 'Patrick Hand SC, cursive',
    Kalam: 'Kalam'
  }

  export const fontGroup = {
    primary: fonts.Chelsea,
    secondary: fonts.Roboto,
    tertiary: fonts.PatrickHand,
  }

  export default colors