import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import reducer from '../reducers/index.js';

const Config = {
    key: 'root',
    storage: storage,
    whitelist: ['auth']
    
}

// This reducer has persistReducer applied to it already

const persistedreducer = persistReducer(Config, reducer);

   const store = createStore(
            persistedreducer,
            {},
            compose(applyMiddleware(thunk)
        ));
export default store;