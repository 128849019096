import {
    ADDBOOK, DELETE, PUTBOOKS, DELETEBOOK, SWIPERIGHT, BOOKSFOUND
} from '../types';


export const addBook = (book, bookState) => async dispatch => {
    if (bookState === null) {
        var sendBook = [book]
    } else {
        var sendBook = [...bookState, book]
    }
    dispatch({ type: ADDBOOK, payload: sendBook })
}

export const putBooksInRedux = (book, currentBooks, email, comment, genre) => async dispatch => {
    try { var blurb = book.volumeInfo.description; } catch { let blurb = null }
    try { var image = book.volumeInfo.imageLinks.thumbnail; } catch { var image = null; }
    try { var title = book.volumeInfo.title; } catch { }
    try {
        var ISBN = { code: null }; book.volumeInfo.industryIdentifiers.map(item => {
            if (item.type === 'ISBN_13') {
                ISBN.code = item.identifier
            } else { }
        })
    } catch { }
    try { var author = book.volumeInfo.authors.slice(0, 1)[0] } catch { var author = null }
    var timeStamp = Date.now()
    var picid = email + timeStamp
    var bookData = JSON.stringify({ image, title, isbn: ISBN.code, blurb, picid, comment, email, timeStamp, author, genre })

    // Add validation here: if isbn is in list already, then reject
    fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}upload_books_website`, {
        method: 'POST',
        headers:
        {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: bookData
    }).then(async res => {
        let response = await res.json();
        if (response.success === true) {
            if (currentBooks) {
                let sendtoRedux = [...currentBooks, response.bookData]
                dispatch({ type: ADDBOOK, payload: sendtoRedux })
            } else {
                dispatch({ type: ADDBOOK, payload: [response.bookData] })
            }
        } else {
            alert('failed to add book to your shelf')
        }
    })


    // dispatch({type: PUTBOOKS, payload: sendBook})
}

export const getProfileInformation = (email) => async dispatch => {

    let body = JSON.stringify({ id: email })
    fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}get_profile_info`, {
        method: 'POST',
        headers:
        {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: body
    }).then(async res => {
        let response = await res.json();
        if (response && response.userDetails) {
            dispatch({ type: PUTBOOKS, payload: response })
        }
    })


    // dispatch({type: PUTBOOKS, payload: sendBook})
}

export const DeleteBooks = () => async dispatch => {
    dispatch({ type: DELETE })
}

export const DeleteBook = (picid, currentBooks) => async dispatch => {
    let body = JSON.stringify({ picid: picid })
    fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}deletebook`, {
        method: 'POST',
        headers:
        {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: body
    }).then(async res => {
        let final = currentBooks.filter(function (item) {
            return item.picid !== picid
        })
        dispatch({ type: DELETEBOOK, payload: final })
    }).catch(err => null)

}

export const swipeRight = (ownID, IDofBookOwner, bookid, picurl, nameofBookOwner, ownName, profilepic, bookOwnerProfilePic, isbn, genre, title, author) => async dispatch => {
    // console.log(ownID, IDofBookOwner, bookid, picurl, nameofBookOwner, ownName, profilepic, bookOwnerProfilePic, isbn, genre)
    let body = await JSON.stringify({ email: ownID, emailtwo: IDofBookOwner, book_id: bookid, bookurl: picurl, firstname: nameofBookOwner, ownname: ownName, ownpicture: profilepic, otherpicture: bookOwnerProfilePic, isbn, genre, title, author })
    fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}swiperight`, {
        method: 'POST',
        headers:
        {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: body
    }).then(async res => {
        dispatch({ type: SWIPERIGHT, payload: bookid })
    }).catch(err => alert('failed to like this book'))
}

export const getBooksToView = (distance, latitude, longitude, id) => dispatch => {
    var localquery = `${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}getbooks?distance=${distance}&Longitude=${longitude}&Latitude=${latitude}&id=${id}`;
    // var rootquery = ``${ process.env.NODE_ENV !== 'production' ?  `http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'} getbooks?distance=${distance}&Longitude=${null}&Latitude=${latitude}&id=${id}`;
    fetch(localquery,
        {
            method: 'GET',
            headers:
            {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async rez => {
            let result = await rez.json();
            dispatch({ type: BOOKSFOUND, payload: Object.values(result) });
        }).catch(err => null)
}
