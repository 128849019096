/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import facepaint from "facepaint";
import React, { useState, useEffect } from "react";
import { TiInfoLargeOutline } from "react-icons/ti";
import { TiTickOutline } from "react-icons/ti";
import { TiTimesOutline } from "react-icons/ti";
import { fonts, fontGroup } from "./colors";
import BookeyLogo from '../images/BookeyLogo.png';
import { useHistory, useLocation } from "react-router-dom";

export default function HomePageBookCard(props) {
  const history = useHistory();

  function sendToSwapBooksPage() {
    return history.push({ pathname: '/swapbooks' })
  }

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setTimeout(() => setAnimate(true), (props.index * 500) + 700)
  }, [])

  return (
    // <div css={styles.topBookDiv}>
    <div css={styles.topDiv(props.backGroundColorr, animate)}>
      <div css={styles.bookHolderDiv}>
        <img src={props.img} style={{ width: "100%", height: "100%" }} />
      </div>

      <div css={styles.rightSideDiv}>
        <div css={styles.topHalfRhs}>
          <div css={styles.bookNameDiv}>
            <div css={styles.childScrollTextDiv}>
              <p css={styles.titleText}>{props.bookTitle}</p>
            </div>
            <p css={styles.clearAuthorTextStyle}>By {props.bookAuthor}</p>
          </div>
        </div>

        <div css={styles.bottomHalfRhs}>
          <div css={styles.namePicDiv}>
            {/* <p style={{ margin: '0px' }}>{props.first_name}</p> */}
            {/* <p css={styles.nameTextStyle}>name</p> */}
            <div css={styles.profpicdiv}>
              <img
              onClick={() => history.push({ pathname: '/swapbooks'})}
                src={BookeyLogo}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div css={styles.buttonsContainer}>
            <div css={styles.smallcirclediv}>
              <TiTimesOutline onClick={() => history.push({ pathname: '/swapbooks'})} css={styles.tiTimes} />
            </div>
            <div css={styles.smallcirclediv}>
              <TiInfoLargeOutline onClick={() => history.push({ pathname: '/swapbooks'})} css={styles.tiHeart} />
            </div>
            <div css={styles.smallcirclediv}>
              <TiTickOutline onClick={() => history.push({ pathname: '/swapbooks'})} css={styles.tiTick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const breakpoints = [500, 1025, 1400];

const mq = facepaint([
  `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
  `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
  `@media(min-width: ${breakpoints.slice(2, 3)}px)`,
]);

const styles = {
  topDiv: (color, animate) => css(mq({
    display: "flex",
    flexDirection: "row",
    width: [300, '45%', '30%', '30%'],
    maxWidth: [undefined, 400, undefined, undefined],
    minWidth: 200,
    height: [135, '100%', '100%', '100%'],
    opacity: animate === true ? 1 : 0,
    transition: '1s ease-out opacity',
    justifyContent: "space-around",
    borderRadius: "3%",
    overflow: "hidden",
    marginLeft: [0, 20, 25, 30],
    marginRight: [0, 20, 25, 30],
    padding: [10, 10, 20, 20],
    paddingTop: [10, 20, 20, 20],
    paddingBottom: [10, 20, 20, 20],
    background: color,
    maxHeight: 250,
    boxSizing: 'border-box',
  })),
  bookPic: css(
    mq({
      backgroundColor: "black",
    })
  ),

  newDiv: (color) =>
    css(
      mq({
        backgroundColor: color,
      })
    ),

  bookHolderDiv: css(
    mq({
      display: "flex",
      flexDirection: "column",
      width: [100, "40%", "40%", "40%"],
      height: [115, "100%", "100%", "100%"],
      borderRadius: "10%",
      overflow: "hidden",
      paddingRight: "1%",
    })
  ),

  rightSideDiv: css(
    mq({
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      paddingLeft: 10,
    })
  ),

  topHalfRhs: css(
    mq({
      width: "100%",
      height: "65%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxSizing: "border-box",
    })
  ),

  bookNameDiv: css(
    mq({
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "2%",
      overflow: "hidden",
      justifyContent: "space-around",
    })
  ),

  childScrollTextDiv: css(
    mq({
      overflow: "auto",
      width: "105%",
    })
  ),

  titleText: css(
    mq({
      marginRight: "4%",
      marginTop: "0",
      fontSize: [11, 17, 15, 19],
      fontWeight: "bold",
      fontFamily: fontGroup.primary,
      marginBottom: 3,
    })
  ),

  authorNameDiv: css(
    mq({
      width: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
    })
  ),

  clearAuthorTextStyle: css(
    mq({
      marginRight: "3%",
      fontSize: [9, 15, 13, 17],
      fontWeight: 450,
      fontFamily: fontGroup.primary,
      color: "#4a4949",
      marginTop: 6,
      marginBottom: 3,
    })
  ),

  bottomHalfRhs: css(
    mq({
      width: "100%",
      height: "35%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      boxSizing: "border-box",
      //   paddingLeft: "2%",
      paddingBottom: "2%",
    })
  ),

  namePicDiv: css(
    mq({
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      width: ["30%", "20%", "30%", "30%"],
      padding: 3,
      paddingLeft: 0,
    })
  ),
  buttonsContainer: css(
    mq({
      display: "flex",
      flexDirection: "row",
      width: "70%",
      padding: 3,
      justifyContent: "space-between",
    })
  ),

  nameTextStyle: css(
    mq({
      margin: 0,
    })
  ),

  profpicdiv: css(
    mq({
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      borderRadius: 50,
      width: [34, 36, 40, 44],
      height: [34, 36, 40, 44],
      justifyContent: "center",
      overflow: "hidden",
      cursor: "pointer",
      alignSelf: "flex-start",
    })
  ),

  smallcirclediv: css(
    mq({
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      borderRadius: [32, 35, 32, 40],
      width: [32, 35, 32, 40],
      height: [32, 35, 32, 40],
      alignSelf: "flex-end",
      justifyContent: "center",
      boxShadow: "2px 4px 10px 0px black",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 20px 0px black",
        transition: "0.1s linear",
      },
    })
  ),

  tiTimes: css(
    mq({
      width: "100%",
      height: "100%",
      color: "black",
      alignSelf: "center",
    })
  ),

  tiHeart: css(
    mq({
      width: "100%",
      height: "100%",
      color: "red",
      alignSelf: "center",
    })
  ),

  tiTick: css(
    mq({
      width: "100%",
      height: "100%",
      color: "green",
      alignSelf: "center",
    })
  ),
};
