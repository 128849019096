import React from 'react';

interface Props {
  method: string
  test? : boolean
  urlPath: string
  body: string
  }

export default async function Fetch(method: string, urlPath: string, body: string, test?: boolean) {
  // const { urlPath, test, method, body } = props
  const link = `${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}${urlPath}`;

  return await fetch(link,
    {
     method: method,
        headers: 
          {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: body
        }
    )
}