import { combineReducers } from 'redux';
import {
    EMAILLOGIN, SIGNUP, LOGOUT, ADDBOOK, DELETE, SIGNIN, SIGNINFAILED,
    UPLOADPHOTO, DELETEBOOK, BOOKSFOUND,
    PUTBOOKS,
    SWIPERIGHT,
    SETNOTIFICATIONS,
    ADDNOTIFICATIONS,
    SIGNUPFAILED,
    SIGNUPNULL,
    TURNLOGINTRUE,
    UPDATEPROFILEINFO,
    NOTIFICATIONSSEEN,
    FACEBOOKLOGIN
} from '../types'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const databaseConfig = {
    key: 'auth',
    storage: storage
}

const authx = (state = { responseJSON: '', login: false, token: '', phone: '', name: '', findbooks: true, fblogin: false, profilepic: null, email: null, signup: false,  notifications: [], numberNotifs: 0, distance: 50, lat: null, long: null, bio: '' }, action) => {
    switch (action.type) {
        case SIGNUP:
            return {
                ...state,
                login: true,
                signup: true,
                email: action.payload.email,
                name: action.payload.name,
            }
        case FACEBOOKLOGIN:
            return {
                ...state,
                login: true,
                profilepic: action.payload.picture,
                email: action.payload.email,
                name: action.payload.name,
            }
        case SIGNUPNULL: {
            return {
                ...state,
                login: true,
                lat: action.payload.location.lat,
                long: action.payload.location.long,
                bio: action.payload.bio,
                profilepic: action.payload.image,
                distance: action.payload.distance,
            }
        }
        case SIGNIN:
            return {
                ...state,
                login: true,
                email: action.payload,
            }
        case SIGNINFAILED:
            return {
                ...state,
                login: false
            }
        case LOGOUT:
            return {
                ...state,
                login: false,
                email: null,
                name: null
            }
        case UPLOADPHOTO:
            return {
                ...state,
                profilepic: action.payload
            }
    case PUTBOOKS:
            return {
                ...state,
                name: action.payload.userDetails.first_name,
                lat: action.payload.userDetails.lat,
                long: action.payload.userDetails.long,
                bio: action.payload.userDetails.bio,
                profilepic: action.payload.userDetails.picture,
                email: action.payload.userDetails.id,
                distance: action.payload.userDetails.distance,
            }
    case UPDATEPROFILEINFO:
        return {
            ...state,
            distance: action.payload.distance || state.distance,
            profilepic: action.payload.picture || state.profilepic,
            lat: action.payload.lat || state.lat,
            long: action.payload.long || state.long,
        }
    case NOTIFICATIONSSEEN:
        return {
            ...state, 
            numberNotifs: 0,
        }
        default:
            return state;

    }
}

const booksConfig = {
    key: 'books',
    storage: storage
}

const myBooks = (state = { books: null }, action) => {
    switch (action.type) {
        case ADDBOOK:
            return {
                ...state,
                books: action.payload
            }
        case PUTBOOKS:
            return {
                ...state,
                books: action.payload.books
            }
        case DELETE:
            return {
                ...state,
                books: null
            }
        case LOGOUT:
            return {
                ...state,
                login: false,
                email: null,
                name: null
            }
        case DELETEBOOK:
            return {
                ...state,
                books: action.payload
            }
        default:
            return state;

    }
}

const viewBooks = (state = { books: null }, action) => {
    switch (action.type) {
        case BOOKSFOUND:
            return {
                ...state,
                books: action.payload
            }
        case SWIPERIGHT: {
            return {
                ...state,
                books: state.books.filter(item => item.picid !== action.payload)
            }
        }
        default:
            return state;
    }
}

const notifications = (state = {numberNotifs: 0, notifications: [], signUpSuccessful: null}, action) => {
    switch (action.type) {
        case SETNOTIFICATIONS:
            return {
                ...state,
                numberNotifs: action.payload.numberNotifs,
                notifications: action.payload.notifications,
            }
        case  ADDNOTIFICATIONS:
            return {
                ...state,
                numberNotifs: state.numberNotifs + 1,
                notifications: [...state.notifications, action.payload.notifications]
            }
        case SIGNUP:
            return {
                ...state, 
                signUpSuccessful: true
            }
        case LOGOUT:
            return {
                ...state,
                notifications: [],
                numberNotifs: 0,
                signUpSuccessful: false,
            }
        case SIGNUPNULL:
            return {
                ...state,
                signUpSuccessful: null,
            }
    default:
        return state;
    }
}



export default combineReducers({
    auth: persistReducer(databaseConfig, authx),
    books: myBooks,
    bookViewer: viewBooks,
    notifications: notifications,
});






