import {
    EMAILLOGIN,
    SIGNUP,
    VERIFIED,
    LOGOUT,
    SIGNIN,
    SIGNINFAILED,
    SIGNUPFAILED,
    SETNOTIFICATIONS,
    ADDNOTIFICATIONS,
    SIGNUPNULL,
    TURNLOGINTRUE,
    UPDATEPROFILEINFO,
    NOTIFICATIONSSEEN,
    FACEBOOKLOGIN
} from '../types';
import firebase from 'firebase';
import store from '../Store';
import Fetch from '../../containers/Fetch';


export const emailSignUp = (name, email, password, history) => async dispatch => {
    let result = await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().createUserWithEmailAndPassword(email, password).then(res => {
        let uid = firebase.auth().currentUser.uid
        dispatch({ type: SIGNUP, payload: { email: uid, name: name } });
        let body = JSON.stringify({ email: uid, name });
        fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}signup_website`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: body
        }).then(() => {
            history.push('/Mybooks')
        }).catch(err => {alert('couldnt sign you up')})
    })
        .catch(err => {
            alert(err);
            dispatch({type: SIGNUPFAILED})
            })
}

export const emailLogin = (email, password, history) => async dispatch => {
    let result = await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().signInWithEmailAndPassword(email, password).then(res => {
        dispatch({ type: SIGNIN, payload: res.user.uid })
        history.push({ pathname: '/swapbooks'})

    })
        .catch(err => {
            alert(err);
            dispatch({ type: SIGNINFAILED })
        })
}

export const Logout = (history) => async dispatch => {
    firebase.auth().signOut().then(function (res) {
        dispatch({ type: LOGOUT })
        firebase.auth().onAuthStateChanged(user => null)
        history.push({ pathname: '/signup'})

    })
        .catch(err => null)
}

export const changeSignUpSuccessToNullAndUpdateProfileInfo = (location, bio, image, email, distance) => async dispatch => dispatch({type: SIGNUPNULL, payload: {location, bio, image, email, distance}});

// export const getmatches = (userid) => async dispatch => {
//     let link = `${ process.env.NODE_ENV !== 'production' ?  `http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'} getmatches';
//     let bodytosend = JSON.stringify({ phoneid: userid });
//     await fetch(link,
//         {
//             method: 'POST',
//             headers: 
//             {   Accept: 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: bodytosend
//         }).then(async res => {

//             try {let parsed = await res.json()
//             // console.log('parsed',parsed);
//             let keys = Object.keys(parsed);
//             array = []
//             for (j = 0;j < keys.length; j++ ) {
//                 array.push({...parsed[keys[j]], id: keys[j] })
//             }
//             await array.sort(function (a, b) {
//                 return b.timedetails.lastmsgat - a.timedetails.lastmsgat;
//               });
//             // console.log(array[0].timedetails, array[1].timedetails, array[2].timedetails, array[3].timedetails,array[4].timedetails,  'array')

//             // await array.sort((a, b) => {return (b.timedetails.lastmsgat - a.timedetails.lastmsgat)})
//             dispatch({ type: GETMATCHES, payload: array})
//             } catch {
//                 dispatch({type: GETMATCHES, payload: [] })
//             }
//         }).catch(err => console.log(err, 'failure of fetch'))
//     }

export const setNotifications = (numberNotifs, notifications, set) => async dispatch => {
    if (set === true) {
        dispatch({type: SETNOTIFICATIONS, payload: {numberNotifs, notifications} })
    } else {
        const currentNotifs = store.getState().notifications.notifications;
        if (!currentNotifs.find(notif => notif.time === notifications.time && notif.name === notifications.name)) {
            return dispatch({type: ADDNOTIFICATIONS, payload: { notifications } })
        }
    }
}

export  const setNotificationsSeen = () => dispatch => dispatch({type: NOTIFICATIONSSEEN})

export const updateProfileInformation = (distance, picture, lat, long) => dispatch => dispatch(
    { type: UPDATEPROFILEINFO, payload: { distance, picture, lat, long }
 });

 export const facebookLogin = (history, facebookData, path) => async dispatch => {
     await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
     const credential = firebase.auth.FacebookAuthProvider.credential(facebookData.accessToken);
    await firebase.auth().signInWithCredential(credential)
        .then(res => {
            // firebasex.uid = res.user.uid;
            // console.log(res);
            if (res.additionalUserInfo) {
                let picture = res.additionalUserInfo.profile.picture.data.url;
                let name = res.additionalUserInfo.profile.first_name;
                // let email = res.profile.email;
                let uid = res.user.uid;
                let body = JSON.stringify({name, email: uid, picture });
                Fetch('POST', 'signup_website',body ).then(result => {
                    dispatch({ type: FACEBOOKLOGIN, payload: { picture, email: uid, name } });
                    history.push(path)
                }
                )
            }

            // console.log(res.user.uid)
            // console.log(res,`hi ${responseJSON.first_name} you are logged in`)
        }).catch(err => {
            if (err.code && err.code.includes('auth/account-exists-with-different')) {
                alert('The email address associated to your Facebook has already been used to register with Bookey. Please login with your email and Bookey password.')
            }
            alert('Facebook authentication failed. Try signing up with email')});
    // await firebase.auth().onAuthStateChanged(user => console.log(user,'user'));
 }