import React, {useEffect, useState , useRef} from 'react'



// export default function Image({src , className}) {
//     const [load, changeload] = React.useState(`${className} invisible`);
    
//     React.useEffect(() => {
//         changeload(`${className} visible`)
//     })

//     function onLoad(){
//         changeload(`${className} visible`)
//     }

//     return (
//     <img src={src} className={load}
//      onLoad={onLoad}
//     />
//     )
// }

export default function Image({ src, className, div,rel, children }) {
    const [load, changeLoad] = useState(`${className} invisible`)
    const imageRef = useRef()
  
    function onLoad() {
      changeLoad(`${className} visible`)
    }
  
    useEffect(() => {
      if (imageRef.current && imageRef.current.complete) {
        onLoad()
      }
    }, [imageRef.current])
  
    return div ?
    <div src={src} className={load} onLoad={onLoad} ref={imageRef} >
      {children}
      </div> :
     <img rel={rel} src={src} className={load} onLoad={onLoad} ref={imageRef} />
  }