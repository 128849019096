
import {
    EMAILLOGIN,
    SIGNUP,
    VERIFIED,
    LOGOUT,
    SIGNIN,
    SIGNINFAILED,
    UPLOADPHOTO
} from '../types';

export const uploadphoto = (photo, picnumb, phoneid) => async dispatch => {
    let photoname2 = `${phoneid}${picnumb}`;
      let link = `${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}sign-s3?file-name=${photoname2}`;
      
      
      let up2 = async (file, signedRequest, url) => {
        function urlToBlob(url) {
          return new Promise((resolve, reject) => {
              var xhr = new XMLHttpRequest();
              xhr.onerror = reject;
              xhr.onreadystatechange = () => {
                  if (xhr.readyState === 4) {
                      resolve(xhr.response);
                  }
              };
              xhr.open('GET', url);
              xhr.responseType = 'blob'; // convert type
              xhr.send();
          })
        }
      
        const lol = await urlToBlob(file);
        await fetch(signedRequest, { method: 'PUT', body: lol }).
            then(() => dispatch({type: UPLOADPHOTO, payload:  url }))
                .catch(() => console.log('image upload failed'));
      }

      await fetch(link,
            {
                method: 'GET',
                headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
                }).then( async (resp) => {
    let response = await resp.json();   
    up2(photo, response.signedRequest, response.url);
})
  .catch((error) => null)
  }