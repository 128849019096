/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core';
import facepaint from 'facepaint';
import React, { useEffect, useState, lazy } from "react";
import "./App.css";
import AB1 from './images/picone.png';
import AB2 from './images/aboutus3.png';
import AB3 from './images/Group190.png';
import { FaBeer, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import Fire from "./firebaseinit";
import Images from "./images";
import Typist from "react-typist";
import PiMedia from "./images/PIMEDIA.png";
import HomePageCard from './components/homePageBookCard';
import { fontGroup, fonts } from './components/colors';
import GetDimensions from './components/getDimensions';
import ImageNew from './components/imageNew';


let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


function Home(props) {

  const {width, height} = GetDimensions();

      const [darkmode,setdarkmode ] = useState(false);
      const [username,setusername ] = useState("");
      const [submit,setsubmit ] = useState(false);
      const [loading,setloading ] = useState(true);
      const [i1,seti1 ] = useState(false);
      const [i2,seti2 ] = useState(false);
      const [i3,seti3 ] = useState(false);
      const [i4,seti4 ] = useState(false);
      const [i5,seti5 ] = useState(false);
      const [i6,seti6 ] = useState(false);
      const [data,setdata ] = useState(null);
      const [example,setexample ] = useState(true);
      const [anim1,setanim1 ] = useState("FUCKOFF");
      const [bar1,setbar1 ] = useState("bar1");
      const [text1,settext1 ] = useState("text1");
      const [text2,settext2 ] = useState("text2");
      const [bar2,setbar2 ] = useState("bar2");
      const [text3,settext3 ] = useState("text3");
      const [bar3,setbar3 ] = useState("bar3");
      const [menu,setmenu ] = useState(false);
      const [bookPreview,setbookPreview ] = useState(null);
      const [phoneBookPreview,setphoneBookPreview ] = useState(null);
      const [typingdone,settypingdone ] = useState(false);
      const [Ydiff,setYdiff ] = useState(0);
      const [Ydiff2,setYdiff2 ] = useState(0);
      const [activate,setactivate ] = useState(true);
      const [speed,setspeed ] = useState(0);
    

  let changehandler = event => {
    setusername(event.target.value);
  };

  let writetodb = () => {
    var date = Date.now().toString();
    var storage = Fire.storage()
      .ref(date)
      .putString(username)
    setsubmit(true);
  };

  let Thanksmessage = () => {
    if (submit == true) {
      return <p>Thank you for your help!</p>;
    } else {
    }
  };

  useEffect(() => {
     fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}website_seebooks_experiment`,
      {
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ limit: 10 })
      }).then(async res => {
        var bookdata = await res.json();
        setbookPreview(bookdata.booksArray.slice(0, 3));
        setphoneBookPreview(bookdata.booksArray.slice(0, 2));
      });

    setTimeout(() => setloading(false), 600);
  }, [])


  function typistSection() {
    return (
      <Typist
        cursor={{ show: false, blink: true, element: '|' }}
        className='typistDiv'
        onTypingDone={() => setTimeout(settypingdone(true), 100)}
        style={{ position: 'relative' }}
      >
        <Typist.Delay ms={400} />
        <p css={styles.matchText} >MATCH</p>
        <Typist.Delay ms={150} />
        <p css={styles.swapText(['65%', '60%', '60%', '60%'])} >SWAP</p>
        <Typist.Delay ms={150} />
        <p css={styles.readText} >READ</p>

      </Typist>
    )
  }

  function blueBottomContainer(className) {
    return (
      <div className={className}>
       <div css={styles.socialMediaDiv} >
          <p style={{ marginLeft: 20, color: "black", fontFamily: fontGroup.tertiary, marginBottom: 10, fontSize: 20 }}>Give us feedback and help us grow!</p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", flex: 1 }}>
            <div style={{ flexDirection: "row", display: "flex",alignItems:'flex-start', flex: 1, minHeight: 100 }}>
              <textarea rows={5} value={username}
                onChange={text => changehandler(text)}
                style={{ width: "60%", height: "50%", marginLeft: 20, backgroundColor: "#ffffff", borderRadius: 5, borderwidth: 0, border: 0, boxShadow: "1px 3px 2px grey", boxSizing: 'border-box' }}></textarea>
              <button onClick={writetodb}
                style={{ fontFamily: fontGroup.tertiary,  flexShrink: 1, marginLeft: 20, height: 60, background: "radial-gradient(#24b9ed, white)", border: '5px solid white', cursor:'pointer', fontSize: 20, height: '50%', borderRadius: 5  }}
              >
                submit
                </button>
            </div>
          </div>

          {Thanksmessage()}
          <p style={{ marginLeft: 20, color: "black", fontFamily: fontGroup.tertiary, fontSize: 20, marginBottom: 5 }}>
            Find us on social media
            </p>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <a href="https://www.facebook.com/Bookey-546467819221955" style={{ color: "black" }}>
              <FaFacebook size={30} style={{ display: "flex", marginTop: 0, marginLeft: 20 }} />
            </a>
            <a href="https://www.instagram.com/bookeyapp/" style={{ color: "black" }}>
              <FaInstagram size={30} style={{ display: "flex", marginTop: 0, marginLeft: 20 }} />
            </a>
          </div> 
        </div>
        {className === 'blueBottomContainerPhone' ? <div className="Pressx">
          <p style={{ fontFamily: fontGroup.tertiary, fontSize: 30, color: "black", alignSelf: "center" }}> In The News</p>
          <div style={{ flexDirection: "row", display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }} >
              <a href="https://uclpimedia.com/online/amir-jabarivasal-on-coding-careers-app-creation-and-loneliness-in-london">
                {/* <Images image={PiMedia} alt="PiM" class="screenshotx" css={styles.newsImages} /> */}
                <img src={PiMedia} className="screenshotx" css={styles.newsImages} loading="lazy" />
              </a>
              
              <a href="https://www.linkedin.com/pulse/i-taught-myself-how-code-made-bookey-5-months-amir-jabarivasal/">
                <FaLinkedin css={styles.newsImages} className="screenshotx" css={styles.newsImages} />
              </a>
            </div>
          </div>
        </div> :
        <div className="Pressx">
          <p data-aos="fade-up" style={{ fontFamily: fontGroup.tertiary, fontSize: 30, color: "white", alignSelf: "center" }}> In The News</p>
          <div style={{ flexDirection: "row", display: "flex", justifyContent: "center" }}>
            <div data-aos="fade-up" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }} >
              <a href="https://uclpimedia.com/online/amir-jabarivasal-on-coding-careers-app-creation-and-loneliness-in-london">
                <img loading="lazy" src={PiMedia} alt="PiM" className="screenshotx" css={styles.newsImages} />
              </a>
              <a href="https://www.linkedin.com/pulse/i-taught-myself-how-code-made-bookey-5-months-amir-jabarivasal/">
                <FaLinkedin class="screenshotx" css={styles.newsImages} />
              </a>
            </div>
          </div>
        </div>}
      </div>
    )
  }

  function howItWorksDiv(className, skew) {
    return (
      <div className={className} >
        <p css={styles.howItWorksTitle} >How it works</p>
        <p css={styles.itWorksLikeTinder} style={{  }}>{" "}It works like Tinder, except you swipe on books instead of people{" "}</p>

        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap" }}>
          <div style={{ marginTop: 30, marginBottom: 20, alignItems: "center", display: "flex", flexDirection: "column", width: 360 }}>
            <p style={{ fontFamily: fontGroup.primary, fontSize: 25, color: "#24b9ed", fontWeight: 'bold', letterSpacing: 5, textAlign:'center' }}>Upload your books</p>
            <img loading="lazy" src={AB1} alt="g5" css={styles.macImage} />
          </div>

          <div style={{ marginTop: 30, marginBottom: 20, alignItems: "center", display: "flex", flexDirection: "column", width: 360 }}>
            <p style={{ alignSelf: "center", fontFamily: fontGroup.primary,letterSpacing: 5, fontSize: 25, color: "#24b9ed", fontWeight: 'bold', textAlign:'center' }}>
              Browse books in your area
                </p>
            <div style={{ flexDirection: "row", display: "flex", alignItems: "center", alignSelf: "center", justifyContent: 'center' }}>
              <img loading="lazy" src={AB2} alt="g6" css={styles.macImage} />
            </div>
          </div>

          <div style={{ marginTop: 30, marginBottom: 20, alignItems: "center", display: "flex", flexDirection: "column", width: 360 }}>
            <p style={{ fontFamily: fontGroup.primary, fontSize: 25, color: "#24b9ed", fontWeight: 'bold', letterSpacing: 5, textAlign:'center' }}>Get matches, meet up and swap</p>
            <div style={{ flexDirection: "row", display: "flex", alignItems: "center", alignSelf: "center", justifyContent: 'center' }}>
              <img loading="lazy" src={AB3} alt="g8" css={styles.lastHowItWorksImg} />
            </div>
          </div>
        </div>
      </div>
    )
  }


  function showBooks(array) {
    const colorsarray = [
      "linear-gradient(90deg,rgba(74, 174, 246,1),rgba(74, 174, 246,0.6))",
      "linear-gradient(90deg,rgba(196, 121, 232,1),rgba(196, 121, 232,0.6))",
      "linear-gradient(90deg,rgba(170, 223, 57,1),rgba(170, 223, 57,0.6))",
    ]
    return array && typingdone === true && array.map((item, index) => {
      return <HomePageCard index={index} img={item.picurl} bookAuthor={item.author} profilepicture={item.picture} bookTitle={item.title} backGroundColorr={colorsarray[index]} />
    }
    )
  }

    if (width >= 1000 && height >= 800) {
      return (
        <div className="app">

          <div className="panel-wrapper-first">
            <div className="panelfirst">
              <ImageNew rel="preload" className='homeBackgroundImage' src={require("./images/bookBackground.jpg")} style={{ backgroundImage: `url(${require("./images/bookBackground.jpg")})`, }} />
                <div css={styles.firstPageDiv(Ydiff)} >
                    <div className="homeBackgroundImage">
                    <div css={styles.matchSwapReadDiv}>
                      {typistSection()}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <div css={styles.bookRowDiv(typingdone)}>
                        {showBooks(bookPreview)}
                      </div>
                    </div>
                    <div onClick={() => {
                      props.history.push({ pathname: "/swapbooks" })
                    }
                    } css={styles.requestSwapDiv}>
                      <p css={styles.requestTextStyle}>Swap books</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <div className="panel-wrapper-second">
            <div className="panelsecond">
              <span style={{height: '100%'}}>{howItWorksDiv('howItWorks', Ydiff)}</span>
            </div>
          </div>

          <div className="panel-wrapper-third">
            <div className="panelthird">
              <span>{blueBottomContainer('blueBottomContainer')}</span>
            </div>
          </div>
          </div>

      )
    } else if (width >= 500) {
      return (
        <div className="app">
          <div className="panel-wrapper-first">
            <div className="panelfirst">
              <ImageNew rel='preload' className='homeBackgroundImage' src={require("./images/bookBackgroundMedium.jpg")} style={{ backgroundImage: `url(${require("./images/bookBackground.jpg")})`, }} />
                <div css={styles.firstPageDiv(Ydiff)} >
                  <div className='homeBackgroundImage' >
                    <div css={styles.matchSwapReadDiv}>
                      {typistSection()}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
                      <div css={styles.bookRowDiv(typingdone)}>
                        {showBooks(bookPreview)}
                      </div>
                    </div>
                    <div onClick={() => {
                      props.history.push({ pathname: "/swapbooks" })
                    }
                    } css={styles.requestSwapDiv}>
                      <p css={styles.requestTextStyle}>Swap books</p>
                    </div>
                  </div>
                </div>
            </div>
          </div>

          <div className="panel-wrapper-second two">
            <div className="panelsecond">
              <span>{howItWorksDiv('howItWorksIpad', Ydiff)}</span>
            </div>
          </div>

          <div className="panel-wrapper-third third">
            <div className="panelthird">
              <span>{blueBottomContainer('blueBottomContainer')}</span>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="app">
          <div css={styles.firstPageDiv()} >
            <div rel='preload' css={styles.backgroundImage}>
              <div css={styles.matchSwapReadDiv}>
                {typistSection()}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', overflowY: 'hidden', position: 'relative' }}>
                <div css={styles.bookRowDiv(typingdone)}>
                  {showBooks(phoneBookPreview)}
                </div>
              </div>
              <div onClick={() => props.history.push({ pathname: "/swapbooks" })} css={styles.requestSwapDiv}>
                <p css={styles.requestTextStyle}>Swap books</p>
              </div>
            </div>
          </div>
          {howItWorksDiv('howItWorksPhone', 0)}
          {blueBottomContainer('blueBottomContainerPhone')}
      </div>
    );
}

export default Home;

const breakpoints = [500, 1025, 1400]

const mq = facepaint([
  `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
  `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
  `@media(min-width: ${breakpoints.slice(2, 3)}px)`
]);

const AnimationName = keyframes({
  '0%': {
    backgroundPosition:'0% 0%', 
  },
  '50%': {
    backgroundPosition:'200% 200%'
  },
  '100%': {
    backgroundPosition:'0% 0%'
  }
})


const styles = {
  topBookDiv: css(mq({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: undefined,
    zIndex: 101,
    backgroundColor: "white",
    minHeight: '150vh',
    flex: 1,
    position: "absolute",
    top: 0,
    transition: "4s",
    borderwidth: 10,
    borderRadius: 10,
    borderColor: "black"
  })),
  matchSwapReadDiv: css(mq({
    // height: [undefined, '55%', '55%', '55%'],
    // flex: [0.8, undefined, undefined, undefined],
    flex: 0.7,
    maxHeight: [170, undefined, undefined, undefined],
    alignSelf: 'center',
    width: '100%',
    padding: [0, 10, 10, 10],
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    // top: [75, 100, 100, 100],
    // marginTop: [10, 20, 20, 20]
  })),
  howItWorksTitle: css(mq({
    fontFamily: fontGroup.primary,
    fontSize: [40, 40, 50, 60],
    letterSpacing: [3, 4, 5, 10] ,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
    marginTop: 20,
  })),
  matchSwapReadText: position => css(mq({
    fontSize: [34, 54, 70, 84],
    fontFamily: fontGroup.primary,
    background: 'linear-gradient(90deg, #24b9ed, #fecee9)',
    fontWeight: 500,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: 0,
    position: 'absolute',
    top: position,
    left: position
  })),
  readText: css(mq({
    fontSize: [34, 74, 70, 84],
    fontFamily: fonts.Kalam,
    background: '#FFFFFF',
    // fontFamily: fontGroup.primary,
    // background: 'linear-gradient(90deg, #24b9ed, #fecee9)',
    fontWeight: 500,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: 0,
    position: 'absolute',
    top: [10, 0, 0, 0],
    // top: position,
    left: ['70%', '72%', '80%', '80%']
  })),
  matchText: css(mq({
    fontSize: [34, 74, 70, 84],
    fontFamily: fonts.Kalam,
    background: '#FFFFFF',
    // fontFamily: fontGroup.primary,
    // background: 'linear-gradient(90deg, #24b9ed, #fecee9)',
    fontWeight: 500,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: 0,
    position: 'absolute',
    top: [10, 0, 0, 0],
    // top: position,
    left: '5%',
  })),
  swapText: position => css(mq({
    fontSize: [34, 74, 70, 84],
    fontFamily: fonts.Kalam,
    background: '#FFFFFF',
    // fontFamily: fontGroup.primary,
    // background: 'linear-gradient(90deg, #24b9ed, #fecee9)',
    fontWeight: 500,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    margin: 0,
    position: 'absolute',
    top: position,
    alignSelf: 'center',
    paddingRight: 2,
  })),
  socialMediaDiv: css(mq({
    height: 300,
    display: "flex",
    // backgroundColor: '#24b9ed',
    justifyContent: "flex-start",
    flexDirection: "column",
    width: [undefined, '100%', undefined, undefined]
  })),
  backButton: css(mq({
    textAlign: "center",
    backgroundColor: "#fecee9",
    flexShrink: 1,
    borderRadius: 20,
    padding: 15,
    boxShadow: "3px 3px 1px black",
    borderWidth: 0
  })),
  itWorksLikeTinder: css(mq({
    flex: 1,
    textAlign: "center",
    fontSize: [20, 20, 30, 36],
    borderRadius: 10,
    marginTop: 0,
    marginRight: [15,3,3,3],
    marginLeft: [15,3,3,3],
    fontFamily: fontGroup.primary
  })),
  bookContainerDiv: css(mq({
    margin: 0,
    backgroundColor: "#fecee9",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "center",
    borderWidth: 5,
    borderRadius: 5,
    borderColor: "black",
    marginTop: 50
  })),
  howItWorksDiv: speed => css(mq({
    width: '105vw',
    backgroundColor: "#fecee9",
    height: '100vh',
    // transform: `skew(${speed}deg)`,
    willChange: 'transform',
    transition: '0.4s',
    position: 'relative'
  })),
  revealBooks: state => css(mq({
    opacity: state === true ? 1 : 0,
    overflow: 'hidden',
    display: state === true ? 'flex' : 'none',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20

  })),
  blueBottomContainer: css(mq({
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    position: 'relative',
  })),
  bookRowDiv: start => css(mq({
    width: start === true ? '100%' : 0,
    transition: '1s ease-out width',
    background: 'linear-gradient(90deg, rgba(0,10,0, 0.7),  rgba(0,10,30,0.5))',
    padding: [10, 30, 20, 20],
    position: 'relative',
    diplay: 'flex',
    height: [320, 300, 260, 260],
    flexDirection: ['column', 'row', 'row', 'row'],
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-around',

    // flex: 1
    // minWidth: 900
  })),
  // backgroundImage: (scrollY, z, activate) => css(mq({
  //   backgroundImage: `url(${require("./images/bookBackground.jpg")})`,
  //   padding: [10, 20, 30, 40],
  //   diplay: 'flex',
  //   height: 300,
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   boxSizing: 'border-box',
  //   display: 'flex',
  //   justifyContent: 'space-around',
  // })),
  backgroundImage: css(mq({
    backgroundImage: `url(${require("./images/bookBackgroundMobile.jpg")})`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: ['101vw', '105vw', '105vw', '105vw'],
    height: '100vh',
    boxSizing: 'border-box',
    paddingBottom: 15,
    paddingTop: [70, 100, 100, 100],
    backgroundSize: 'cover',
    position: 'relative',
    alignSelf: 'center',
    justifyContent: ['flex-start','space-around','space-around','space-around']
  })),
  firstPageDiv: speed => css(mq({
    // transform: `skew(${speed}deg)`,
    transition: '0.5s',
    willChange: 'transform',
    position: 'relative',
    // display: 'flex',
    // flexDirection:'column',
    // boxSizing: 'border-box',
    // alignSelf:'center',
    // justifyContent:'space-around'
  })),

  requestSwapDiv: css(
    mq({
      height: ['7%', 80, 70, 70],
      minHeight: 40,
      width: ['70%', 300, 300, 300],
      display: "flex",
      flexDirection: "row",
      marginTop: [10, null, null, null],
      boxSizing: "border-box",
      overflow: "visible",
      alignItems: "center",
      justifyContent: 'center',
      border: '4px solid black',
      borderRadius: 50,
      backgroundColor: '#F63C3C',
      boxShadow: "0px 2px 7px 0px #000000",
      background: 'linear-gradient(90deg, #24B9ED, white)',
      backgroundSize: '400% 400%',
      position: 'relative',
      animation: `${AnimationName} 20s linear infinite`,
      alignSelf: 'center',
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 20px 0px black",
        transition: "0.1s linear",
      },
    })
  ),

  requestTextStyle: css(
    mq({
      margin: 0,
      fontSize: [26, 38, 34, 34],
      // fontWeight: "bold",
      color: "white",
      fontFamily: fontGroup.primary
    })
  ),

  newsImages: css(mq({
    display: "flex", cursor: "pointer", height: 200, width: 150, color: 'black',
    '&:hover': {
      opacity: 0.5
    }
  })),

  lastHowItWorksImg: css(
    mq({
      height: [undefined, 300, 300, 300], width: ['85%', 400, 400, 400]
    })
  ),
  macImage: css(mq({
    height: [undefined, undefined, '300px', '300px'],
    width: ['85%', undefined, undefined, undefined],
    maxWidth: '100%',
    maxHeight: 400,
    objectFit: 'contain'
  })),
}
