export const EMAILLOGIN = 'email_login';
export const SIGNUP = 'sign_up';
export const VERIFIED = 'login_verified';
export const LOGOUT = 'logout';
export const ADDBOOK = 'add_book';
export const DELETE  = 'delete book';
export const SIGNIN  = 'sign in';
export const SIGNINFAILED  = 'signin failure';
export const UPLOADPHOTO = 'upload_photo';
export const PUTBOOKS = 'put books from redux';
export const DELETEBOOK = 'delete a single book';
export const SWIPERIGHT = 'swipe right on a book';
export const BOOKSFOUND = 'found books for you to explore';
export const SIGNUPFAILED = 'sign up failed';
export const SETNOTIFICATIONS = 'set notifications initial';
export const ADDNOTIFICATIONS = 'add notifications';
export const SIGNUPNULL = 'sign up change to null';
export const TURNLOGINTRUE = 'turnlogintrue';
export const UPDATEPROFILEINFO = 'updateprofileinfo';
export const NOTIFICATIONSSEEN = 'seenNotifications';
export const FACEBOOKLOGIN = 'loginwithfacebook';