/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import facepaint from "facepaint";
import React from "react";



export default function Loading(props) {

return (
  <div css={styles.requestSwapDiv}>
  </div>
)
}


const breakpoints = [500, 1025, 1400];

const AnimationName = keyframes({
  '0%': {
    backgroundPosition:'0% 0%', 
  },
  '50%': {
    backgroundPosition:'200% 200%'
  },
  '100%': {
    backgroundPosition:'-200% 0%'
  }
})

const mq = facepaint([
  `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
  `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
  `@media(min-width: ${breakpoints.slice(2, 3)}px)`,
]);

const styles = {

    requestSwapDiv: css(
    mq({
      height: '100vh',
      minHeight: 40,
      width: '100vw',
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      overflow: "visible",
      alignItems: "center",
      justifyContent: 'center',
      backgroundColor: '#F63C3C',
      background: 'linear-gradient(90deg, #24B9ED, white)',
      backgroundSize: '400% 400%',
      position: 'relative',
      animation: `${AnimationName} 2s linear infinite`,
      alignSelf: 'center',
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 20px 0px black",
        transition: "0.1s linear",
      },
    })
  ),
}