import firebase from 'firebase';

var config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: "AIzaSyD_Yn43OLumRFHmfwj9of70GzVEsBtoNNk",
  authDomain: "bookr-df75e.firebaseapp.com",
  databaseURL: "https://bookr-df75e.firebaseio.com",
  projectId: "bookr-df75e",
  storageBucket: "bookr-df75e.appspot.com",
  messagingSenderId: "140561353113",
  appId: "1:140561353113:web:15aa4ffefa207375d72b08"
};
var fire = firebase.initializeApp(config);
export default fire;
