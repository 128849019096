/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx, keyframes } from '@emotion/core'
import { NavLink } from 'react-router-dom';
import facepaint from 'facepaint'
import svgopen from './images/open.svg'
import { useHistory } from "react-router-dom";
import colors from './components/colors'
import { connect } from 'react-redux';
import { Logout } from './redux/actions';
import { FiMenu } from "react-icons/fi";


function Menubar(props) {
    const history = useHistory();
    const [menu, menuSetter] = useState(false)
    const { noBackground } = props


    let seebooks = (device) => {

        if (props.viewBooks) {
            history.push({ pathname: 'viewbooks', state: { detail: '0', device: device } })
        }
        else {
            fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'}website_seebooks_new`)
                .then(async res => {
                    var ee = await res.json()
                    history.push({ pathname: '/viewbooks', state: { detail: ee, device: device } })
                })
        }

    }
    return (
        <React.Fragment>
            <div css={styles.menuBurger(noBackground)} onClick={() => menuSetter(!menu)}>
                {/* <img src={svgopen} /> */}
                <FiMenu style={{ height: '100%', width: '100%' }} />
            </div>
            <div
                css={(menu === false) ? styles.menuInvis : styles.menuVis}
            //  className={(menu === false) ? 'menu-invis' :'menu-vis'}
            >
                <div css={styles.happy(menu)}>
                    <NavLink onClick={() => menuSetter(false)} exact={true} activeClassName='is-active' className='Homemobile' to='/' >HOME</NavLink>
                    <p className='Homemobile' activeClassName='is-active' onClick={() => {
                        menuSetter(false)
                        // seebooks('mobile')
                        history.push({ pathname: "/swapbooks" })
                    }}>SWAP BOOKS</p>
                    <NavLink onClick={() => menuSetter(false)} activeClassName='is-active' className='About-usmobile' to='/aboutus' >ABOUT US</NavLink>
                    

                    {!props.login && (
                        <React.Fragment>
                            <NavLink onClick={() => menuSetter(false)} exact={true} activeClassName='is-active' className='About-usmobile' to='/signup' >SIGN UP</NavLink>

                        </React.Fragment>
                    )}
                    {props.login && (
                        <React.Fragment>
                            <NavLink onClick={() => menuSetter(false)} activeClassName='is-active' className='About-usmobile' to={props.login ? '/myaccount' :'/signup'} >PROFILE</NavLink>
                            <NavLink onClick={() => menuSetter(false)} activeClassName='is-active' className='About-usmobile' to='/Mybooks' >ADD BOOKS</NavLink>
                            <p className='Homemobile' activeClassName='is-active' onClick={() => {
                                menuSetter(false)
                                props.Logout(history)
                            }}>LOG OUT</p>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ auth, bookViewer }) => {
    return {
        login: auth.login,
        viewBooks: bookViewer.books
    }
}

export default connect(mapStateToProps, { Logout })(Menubar)

const menuBarAnimate = keyframes({
    '0%': {
        width: 0,
        height: 0,
        bottom: '100%',
        opacity: 0,
        left: 0,

    },
    '100%': {
        width: '100vw', height: '100%', position: 'fixed', opacity: 1
    }


})

const breakpoints = [500, 1025, 1400]

const mq = facepaint([
    `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
    `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
    `@media(min-width: ${breakpoints.slice(2, 3)}px)`
])

const styles = {
    happy: (menu) => css(mq({
        flexDirection: 'column',
        justifyContent: 'center',
        display: menu === false ? 'none' : 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
        zIndex: 1000,
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        background: 'radial-gradient(#fecee9, white);',
    })),
    menuInvis: css(mq({
        bottom: 0,
        left: 0,
        position: 'absolute',
        zIndex: 100,
        height: 0,
        width: 0,
        display: 'none',
        background: 'linear-gradient(180deg,rgba(36, 185, 237,0.8),rgba(36, 185, 237,0.05))'
    })),
    menuVis: css(mq({
        bottom: 0,
        left: 0,
        position: 'absolute',
        zIndex: 104,
        // background: 'linear-gradient(180deg,rgba(36, 185, 237,1),rgba(36, 185, 237,0.9))',
        background: 'linear-gradient(180deg, white, #eaeaea)',
        height: 0,
        width: 0,
        animation: `${menuBarAnimate} 0.3s cubic-bezier(1, 0, 0, 1) forwards `,
        display: 'fixed',
        flexDirection: 'column',
    })),
    menuBurger: (props) => css(mq({
        padding: 10,
        top: 0,
        left: 0,
        width: 35,
        backgroundColor: props ? 'none' : 'black',
        zIndex: 105,
        display: ['flex', 'flex', 'none', 'none'],
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
    })),
    Homemobile: {
        fontSize: 15,
        marginRight: 10,
        color: '#24b9ed',
        textDecoration: 'none',
    }
}

