import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default class extends React.Component {
    // Notes -- 
    // props: 
    // {style = The style property for the div and therefore the shape of the Skeleton}
    // {class = The CSS className value (name) }
    // {image = the image that you're importing in}
    constructor(props) {
        super(props);
        this.state = { imageload: false, height: 0 };
        this.myRef = React.createRef();
        this.imageRef = React.createRef();
        
      }


    // componentWillMount() {
    //     const imageZ = new Image();
    //     imageZ.onload = () => this.setState({ imageload: true });
    //     imageZ.src = this.props.image;
    // }

    componentDidMount(){
       this.setState({height: this.myRef.current.clientHeight, width: this.myRef.current.clientWidth })
    }

    componentDidUpdate() {
      if (this.imageRef.current && this.imageRef.current.complete) {
        this.setState({ imageload: true})
      }
    }


    render() {
    return (
    <div style={this.props.style} ref={this.myRef}  >
        {
        this.state.imageload ?
          <img loading="lazy" ref={this.imageRef} onLoad={() => this.setState({imageload: true})} src={this.props.image} alt="g1" className={this.props.class} /> : 
        <Skeleton height={this.state.height} width={this.state.width} />
        } 
    </div>)
    }
}