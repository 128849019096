/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core'
import React, { useCallback, useState, useEffect, lazy, Suspense } from 'react'
import facepaint from 'facepaint';
import Home from "./Home.js";
import { Switch, Route } from "react-router-dom";
import Navbar from "./Navbar";
import ReactGA from "react-ga";
import AOS from "aos";
import "aos/dist/aos.css";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/Store";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withRouter } from "react-router";
import StartUp from "./components/startup";
import Loading from './components/loading';
// import Aboutus from "./aboutus";
const Aboutus = lazy(() => import('./aboutus'));

// import ViewProfile from "./viewProfile";
const ViewProfile = lazy(() => import('./viewProfile'));
// import Animations from "./components/swapbooks";
const Animations = lazy(() => import('./components/swapbooks'));

// import Matches from "./matches";
const Matches = lazy(() => import('./matches'));
// import AddBooks from "./screens/addBooks";
const AddBooks = lazy(() => import('./screens/addBooks'));
// import RayPopUp from "./components/raypopupLowerCase.js";
const RayPopUp = lazy(() => import("./components/raypopupLowerCase.js"));
// import SignInPage from "./components/SignInPage.js";
const SignInPage = lazy(() => import("./components/SignInPage.js"));
const SignUp = lazy(() => import("./components/signUp.js"));
// import SignUp from "./components/signUp.js";
// import CreateProfile from "./components/createProfile.js";
const CreateProfile = lazy(() => import('./components/createProfile.js'));
// import SetLocation from "./components/setLocation.js";
const SetLocation = lazy(() => import("./components/setLocation.js"))
// import ChatPage from "./components/mobileChatPage.js";
const ChatPage = lazy(() => import("./components/mobileChatPage.js"));
// import profilePageReal from './profilePageReal.js';
const profilePageReal = lazy(() => import('./profilePageReal.js'));



const persistor = persistStore(store);
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menu: false, animate: false, gaInitialised: false, mobile: true };
  }

  googleAnalytics() {
    if (!this.state.gaInitialised) {
      ReactGA.initialize("UA-149196391-1");
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
    if (window.screen.width > 500) {
      this.setState({ mobile: false})
    }
  }



  toggleMenu = () => {
    this.setState((prevState, state) => {
      return { menu: !prevState.menu };
    });
  }

  AnimatedSwitch(location, mobile) {
    return mobile ? 
    (
      <div css={styles.animateDiv}>
      
              <Navbar />
              <Suspense fallback={<Loading />}>
              <Switch location={location} >
                <Route exact path="/" component={Home} />
                <Route path="/aboutus" component={Aboutus} />
                <Route path="/signup" component={SignUp} />
                <Route path="/Mybooks" component={AddBooks} />
                <Route path="/viewprofile" component={ViewProfile} />
                <Route path="/swapbooks" component={Animations} />
                <Route path="/matches" component={Matches} />
                <Route path="/popup" component={RayPopUp} />
                <Route path="/signin" component={SignInPage} />
                <Route path="/createprofile" component={CreateProfile} />
                <Route path="/setlocation" component={SetLocation} />
                <Route path="/chatpage" component={ChatPage} />
                <Route path='/myaccount' component={profilePageReal} / >
              </Switch >
              </Suspense>
      </div>
    )
    :
    (
      <div css={styles.animateDiv}>
        <TransitionGroup className='transgroup'>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={500}
          >

            <section className="route-section">
              <Navbar />
              <Suspense fallback={<Loading />}>
              < Switch location={location} >
                <Route exact path="/" component={Home} />
                <Route path="/loading" component={Loading} />
                <Route path="/aboutus" component={Aboutus} />
                <Route path="/signup" component={SignUp} />
                <Route path="/Mybooks" component={AddBooks} />
                <Route path="/viewprofile" component={ViewProfile} />
                <Route path="/swapbooks" component={Animations} />
                <Route path="/matches" component={Matches} />
                <Route path="/popup" component={RayPopUp} />
                <Route path="/signin" component={SignInPage} />
                <Route path="/createprofile" component={CreateProfile} />
                <Route path="/setlocation" component={SetLocation} />
                <Route path="/chatpage" component={ChatPage} />
                <Route path='/myaccount' component={profilePageReal} / >
              </Switch >
              </Suspense>
            </section>

          </CSSTransition>
        </TransitionGroup>
      </div>
    )
      ;
  }

  render() {
    {process.env.NODE_ENV === 'production' && this.googleAnalytics()}
    return (
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<div><p>lol</p></div>}
        >
          <div css={styles.containerDiv}>
            <StartUp>

              {this.AnimatedSwitch(this.props.location, this.state.mobile)}
            </StartUp>
          </div>

        </PersistGate>
      </Provider >
    );
  }
}

export default withRouter(App);

const breakpoints = [500, 1025, 1400]

const mq = facepaint([
  `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
  `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
  `@media(min-width: ${breakpoints.slice(2, 3)}px)`
])

const fadeAnimation = keyframes({
  '0%': {
    opacity: 1
  },

  '1%': {
    opacity: 0
  },

  "99%": {
    opacity: 1
  },
  '100%': {
    display: 'none'
  }
})

const styles = {
  containerDiv: css(mq({
    display: 'block',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    opacity: 1,
  })),
  animate: animate => css(mq({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1000,
    backgroundColor: 'white'
    // animate: animate === true ? `${fadeAnimation} 0.5s forwards linear ` : ''
  })),
  topDiv: css(mq({
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 400,
    backgroundColor: '#6385FF'
  })),
  animateDiv: css(mq({
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100vh',
    '.fade-enter': {
      opacity: 0.01,
      // display: 'flex'
    },
    '.fade-enter.fade-enter-active': {
      opacity: 1,
      transition: 'opacity 500ms ease-in',
      // display: 'flex',
      // height: '100vh'
    },
    '.fade-exit': {
      opacity: 1,
      // display: 'flex'
    },
    '.fade-exit.fade-exit-active': {
      opacity: 0.01,
      transition: 'opacity 500ms ease-in',
      // display: 'flex'
    }
  })),
  // sectionDiv: css(mq({
  //   position: 'absolute',
  //   display: 'flex',
  //   width: '100%',
  //   height: '100%',
  //   top: 0,
  //   left: 0,
  // })),
}