/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import facepaint from 'facepaint';
import Menubar from './menubar'
import { withRouter } from 'react-router-dom';
import BookeyLogoSVG from './images/BookeyLogo.svg'
import { fontGroup } from './components/colors';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { Logout } from './redux/actions'
import { compose } from 'redux';
import BookeyText from './images/bookeytext.png';
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { background: 'green' }
  }

  seebooks(device) {
    fetch(`${ process.env.NODE_ENV !== 'production' ?  'http://192.168.0.38:8080/' : 'https://bookrxox.herokuapp.com/'} website_seebooks_new`).then(async res => {
      var bookdata = await res.json();
      this.props.history.push({
        pathname: "/viewbooks",
        state: { detail: bookdata, device: device }
      });
    });
  }

  navToPage(route, state = {}) {
    this.props.history.push({
      pathname: route,
      state
    });
  }

  navTitles(titles) {
    return titles.map(item => <p onClick={() => {
      if (item.title === 'Sign out') {
        this.props.Logout();
      }
      this.navToPage(item.route);
    }
    }
      css={styles.navTitles}
    >
      {item.title}
    </p>
    )
  }
  render() {
    if (this.props.location.pathname === '/viewbooks' || this.props.location.pathname === '/chatpage') {
      return (<div style={{ display: 'none' }} />
      )
    } else {
      return (
        <div>
          <MediaQuery minDeviceWidth={500}>
            <div css={styles.topDiv(this.props.location.pathname)}>

              <div css={styles.navigationBar} >
                  <div css={styles.logoDiv}>
                  <img onClick={() => this.navToPage('/')} src={BookeyLogoSVG} css={styles.logoImg}></img>
                </div>
                <div css={styles.firstNav}>
                  {this.navTitles([ { title: 'Swap books', route: '/swapbooks' }, { title: 'About us', route: 'aboutus' }])}
                  

                  {this.navTitles([{ title: this.props.login === true ? 'My Account' : 'Sign up', route: this.props.login === true ? '/myaccount' :'/signup' }, { title: this.props.login === true ? 'Sign out' : 'Login', route: this.props.login === true ? '/signup' : '/signin' }])}
                </div>

              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={500} >
            <div css={styles.topDivMobile(this.props.location.pathname)}>
              <Menubar noBackground={true} />
              <div css={styles.navigationBar} >
                <div css={styles.logoDiv}>
                  <img onClick={() => this.props.history.push({ pathname: '/'})} src={BookeyLogoSVG} css={styles.logoImg} />
                </div>
              </div>
            </div>
          </MediaQuery>

        </div>
      )
    }
  }


}

const mapStateToProps = ({ auth }) => ({
  login: auth.login
})
export default compose(withRouter, connect(mapStateToProps, { Logout }))(Navbar);
export var phonenavbarheight = 70;

const breakpoints = [500, 1025, 1400]

const mq = facepaint([
  `@media(min-width: ${breakpoints.slice(0, 1)}px)`,
  `@media(min-width: ${breakpoints.slice(1, 2)}px)`,
  `@media(min-width: ${breakpoints.slice(2, 3)}px)`
])

export var navbarheight = 100;
const styles = {
  topDiv: location => css(mq({
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100vw',
    display: 'flex',
    position: location === "/" ? 'fixed' : 'relative',
    height: navbarheight,
    boxSizing: 'border-box',
    zIndex: 25,
    background: 'linear-gradient(180deg,rgba(255, 255, 255),rgba(203,239,251))',
    boxShadow: '0px 5px 10px 0px #fecee9',
    padding: 12,
  })),
  topDivMobile: location => css(mq({
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100vw',
    display: 'flex',
    position: location === "/" ? 'fixed' : 'relative',
    height: phonenavbarheight,
    boxSizing: 'border-box',
    background: 'linear-gradient(180deg,rgba(255, 255, 255),rgba(203,239,251))',
    zIndex: 25
  })),

  topHalfDiv: css(mq({
    flexDirection: 'column',
    width: '100vw',
    display: 'flex',
    // position: 'relative',
    height: '40%',
    boxSizing: 'border-box',
    justifyContent: 'flex-end'

  })),
  logoText: css(mq({
    fontFamily: fontGroup.primary,
    fontSize: '30px',
    margin: 0,
    alignSelf: 'center',
    fontWeight: '700',
    letterSpacing: 2,
    color: ['black', '#24b9ed', '#24b9ed', '#24b9ed']
  })),
  navTitles: css(mq({
    fontFamily: fontGroup.primary,
    fontSize: [14, 16, 18, 20],
    margin: 0,
    alignSelf: 'center',
    fontWeight: '500',
    letterSpacing: 2,
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    marginRight: [10, 10, 0, 0]
  })),
  logoTextDiv: css(mq({
    height: ['100%', '40%', '40%', '40%'],
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    objectFit: 'contain',
    cursor: 'pointer'
  })),
  logoDiv: css(mq({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    padding: [1, 5, 5, 5],
    justifyContent: ['center', 'flex-start', 'flex-start', 'flex-start'],
    boxSizing: 'border-box',
    cursor: 'pointer'
  })),
  logoImg: css(mq({
    width: 'auto',
    height: '80%',
    objectFit: 'contain',
  })),
  firstNav: css(mq({
    width: '100%',
    marginRight: [0, 50, 100, 120],
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 2
  })),
  secondNav: css(mq({
    width: '50%',
    marginLeft: [0, 50, 100, 120],
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    zIndex: 2
  })),
  navigationBar: css(mq({
    height: '100%',
    position: 'relative',
    flexDirection: ['row', , ,],
    justifyContent: ['center', , , ],
    // background: ['none', 'linear-gradient(180deg, white, #eaeaea)', 'linear-gradient(180deg, white, #eaeaea)', 'linear-gradient(180deg, white, #eaeaea)'],
    width: '100vw',
    display: 'flex',
    boxSizing: 'border-box',

  })),
  maindiv: css(mq({
    flexDirection: ['column', 'column', 'row', 'row'],
    justifyContent: 'space-between',
    flex: 1,
    paddingRight: 5,
    paddingLeft: 5,
    background: ['linear-gradient(180deg, white,white 47%, #24b9ed)', 'linear-gradient(180deg, white,white 47%, #24b9ed)', 'linear-gradient(90deg, white,white 47%, #24b9ed)', 'linear-gradient(90deg, white,white 47%, #24b9ed)'],
    display: 'flex',
  })),
  imgstore: css(mq({
    maxWidth: ['45vw', '45vw', 'none', 'none'],
    height: ['5vh', '5vh', '7vh', '7vh'],
    alignSelf: ['center', 'center', 'flex-end', 'flex-end'],
    display: 'flex',
    marginHorizontal: 10,
    transition: '1s'
  })),
  logo: css(mq({
    display: 'flex',
    flexDirection: 'column',
    width: ['null', 'null', '25%', '25%'],
    alignItems: ['center', 'center', 'null', 'null'],
    alignSelf: ['center', 'none', 'none', 'none'],
    marginBottom: 20
  })),
  appnavbar: css(mq({
    flexDirection: 'row',
    flex: 1,
    background: 'linear-gradient(90deg, black, white)',
    display: ['flex', 'flex'],
    alignItems: 'flex-start',
    marginTop: 10,
    height: 100
  })),
  appstorecontainer: css(mq({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: ['center', 'center', 'flex-end', 'flex-end'],
    justifyContent: ['space-around', 'space-around', 'none', 'none'],
  })),
  happy: css(mq({
    margin: 10,
    fontSize: 20,
    color: '#24b9ed',
    '&:hover': {
      color: 'cyan',
      cursor: 'pointer'
    }
  })),
  appstorelinks: css(mq({
    alignSelf: ['center', 'center', 'flex-end', 'flex-end'],
    alignItems: ['space-betweem', 'space-between', 'flex-end', 'flex-end'],
    display: ['flex', 'flex', 'flex', 'flex'],
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginRight: ['none', 'none', '2vh', '2vh'],
    maxWidth: ['95%', '95%', 'none', 'none'],
    width: ['70%', '70%', 'none', 'none'],
    marginBottom: 20
  })),
  menulinks: css(mq({
    display: ['none', 'none', 'flex', 'flex'],
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: 0,
    right: 30,
  })),
  Homemobile: {
    fontSize: 15,
    marginRight: 10,
    color: '#24b9ed',
    textDecoration: 'none',
  }

}

